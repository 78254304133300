export const styles = {
  rightColTitle: {
    textAlign: "center",
    borderColor: " #DDDDDD",
    borderTopStyle: "solid",
    borderBottomStyle: "solid",
    borderWidth: 2
  },
  rightCol: {
    borderColor: " #DDDDDD",
    borderWidth: 2,
    height: 400,
  },
  addBtnText: {
    background: "linear-gradient(97.75deg, #00B9F1 -11.55%, #034EA2 111.02%)",
    fontWeight: "bold",
    fontSize: 17,
    color: "white"
  },
  addBtnText2: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#3A3B3C"
  },
  inputWraper: {
    backgroundColor: "white",
    fontSize: 14,
    fontWeight: "500",
    color: "#000000"
  },
  btnStyle: {
    background:
      "linear-gradient(155.56deg, #E6DE18 -55%, #438B44 127.5%), #FFFFFF",
    fontWeight: "700",
    fontFamily: "Montserrat",
    fontSize: 14,
    marginTop: 3,
    color: "white"
  },
  btnWrapperStyle: {
    border: "none",
    backgroundColor: "rgb(231, 231, 231)",
    color: "black",
    fontSize: 14,
    fontWeight: "500",
    marginTop: 3,
    opacity: 0.6
  },
  monthLabel: {
    marginLeft: 10,
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: 20,
    color: "#000000",
    marginRight: 22,
    paddingTop: 8
  },
  arrowStyle: {
    backgroundColor: "white",
    height: 38,
    width: 38,
    marginLeft: 16,
    borderRadius: 20,
    shadowColor: "0px 2px 4px -2px rgba(0, 0, 0, 0.15)",
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.18,
    shadowRadius: 0.5,
    elevation: 1,
    borderColor: "#EEEEEE",
    borderWidth: 1
  },
  toolbarStyle: {
    backgroundColor: "#E7E7E7",
    paddingLeft: 3,
    paddingRight: 3,
    width: 265,
    height: 48,
    borderRadius: 10
  },
  inputTextStyle: {
    fontWeight: "500",
    fontSize: 18,
    color: "#000000"
  },
  titleTextStyle: {
    fontSize: 24,
    fontWeight: "600",
    display: "grid"
  },
  labelTextStyle: {
    fontSize: 14,
    opacity: 0.5,
    fontWeight: "500",
    color: "#000000"
  },
  btnTextStyle: {
    background:
      "linear-gradient(155.56deg, #E6DE18 -55%, #438B44 127.5%), linear-gradient(0deg, #4A8E44, #4A8E44), #DFDFDF",
    fontWeight: "bold",
    fontSize: 14,
    paddingLeft: 50,
    paddingRight: 50
  },
  inputLineStyle: {
    backgroundColor: "#D9D9D9",
    height: 1
  },
  teamListStyle: {
    backgroundColor: "#89AF31",
    borderRadius: 5,
    marginBottom: 2,
    marginLeft: 2,
    cursor: "pointer",
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5
  },
  notesListStyle: {
    backgroundColor: "#4C9041",
    borderRadius: 5,
    marginBottom: 2,
    marginLeft: 2,
    cursor: "pointer",
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5
  },
  labelStyle: {
    fontWeight: "500",
    fontSize: 12,
    textAlign: "center",
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    width: 150,
    cursor: "pointer"
  },
  notFoundStyle: {
    fontWeight: "600",
    fontSize: 12,
    textAlign: "center",
    color: "black",
    fontFamily: "Montserrat",
    paddingLeft: 15
  },
  notesTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  headerTextStyle: {
    fontWeight: "500",
    fontFamily: "Montserrat",
    fontsize: 12,
    paddingTop: 10,
    paddingBottom: 10,
    color: "#000000",
    paddingLeft: 5
  },
  pendingTextStyle: {
    fontWeight: "500",
    fontFamily: "Montserrat",
    fontsize: 12,
    paddingTop: 30,
    paddingBottom: 10,
    color: "#000000",
    paddingLeft: 5
  },
  eventViewMonthContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 10
  },
  eventViewMonthTitle: {
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontSize: 12,
    color: "black"
  },
  eventViewWeekContent: {
    height: "100%",
    width: "30px !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 10
  },
  eventViewWeekTitle: {
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontSize: 12,
    color: "black"
  },
  eventViewDayContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 2,
    borderRadius: 5,
    borderWidth: 1.5,
    borderColor: 'black',
    borderStyle: 'solid'
  },
  eventViewDayTitle: {
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontSize: 12,
    color: "black",
  },
  desStyle: {
    fontWeight: "500",
    fontFamily: "Montserrat",
    fontSize: 12,
    color: "black"
  },
  downloadStyle: {
    backgroundColor: "white",
    justifyContent: "center",
    display: "grid",
    borderRadius: 5
  },
  dayWeekStyle: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#000000",
    textAlign: "center",
    fontSize: 12,
    opacity: 0.6
  },
  daysCheckContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: 5
  },
  dayWeekTextStyle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    color: "#000000",
    textAlign: "center",
    fontSize: 30
  },
  dayMonthStyle: {
    fontFamily: "Montserrat",
    fontWeight: "500",
    color: "#000000",
    textAlign: "center",
    fontSize: 26,
    opacity: 0.6
  },
  monthHeaderStyle: {
    height: 90,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  monthDayCellStyle: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "600",
    opacity: 0.6,
    color: "#000000"
  },
  labelfontStyles: {
    fontSize: 14,
    color: "grey",
    fontWeight: "500"
  },
  textArea: {
    opacity: "0.6",
    WebkitBoxShadow: "1px 3px 1px #9E9E9E",
    mozBoxShadow: "1px 3px 1px #9E9E9E",
    boxShadow: "1px 3px 1px #9E9E9E",
    fontSize: 14,
    color: "#000000",
    backgroundColor: "white",
    fontWeight: "400"
  },
  saveBtnStyle: {
    background: "linear-gradient(#E6DE18, #438B44)",
    borderRadius: 15,
    fontSize: 14,
    fontWeight: "700",
    paddingLeft: 86,
    paddingRight: 86,
    paddingTop: 17,
    paddingBottom: 17
  },

  tableHeading: {
    fontSize: 14,
    opacity: 0.5,
    fontWeight: "600",
    paddingBottom: 35
  },
  textFont: {
    fontSize: 14,
    fontWeight: "600",
    borderBottom: "1px solid rgb(212, 212, 212)"
  },
  addBtn: {
    background:
      "linear-gradient(155.56deg, #E6DE18 -55%, #438B44 127.5%), linear-gradient(131.42deg, #00B9F1 13.37%, #034EA2 104.38%), #C4C4C4",
    borderRadius: 5,
    marginTop: 5,
    marginLeft: 5
  },
  btnStyle2: {
    background:
      "linear-gradient(97.75deg, #00B9F1 -11.55%, #034EA2 111.02%), linear-gradient(155.56deg, #E6DE18 -55%, #438B44 127.5%), #FFFFFF",
    borderRadius: 10,
    fontSize: 14,
    fontWeight: "bold"
  },
  flexRowStart: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
  },

  monthlyConfigContainer: {
    fontSize: 14,
    marginTop: 8,
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  weeklyConfigContainer: {
    fontSize: 14,
    marginTop: 8,
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  dailyConfigContainer: {
    fontSize: 14,
    marginTop: 8,
    // display:"flex",
    // flexDirection:"column",
    alignItems: "center"
  },
  endCriteriaContainer: {
    margin: 4,
    textAlign: "center",
    borderTop: "1px solid grey",
    paddingTop: 15
  },
  noEndCriteria: {
    margin: 4,
    width: 300,
    textAlign: "center",
    alignItems: "center",
    flexWrap: "wrap",
    display: "flex",
    flexDirection: "row"
  },
  endByCriteria: {
    margin: 4,
    width: 300,
    textAlign: "center",
    alignItems: "center",
    flexWrap: "wrap",
    display: "flex",
    flexDirection: "row"
  }
}
