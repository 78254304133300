/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useState} from "react";

import {Link, useNavigate} from "react-router-dom";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import {useStores} from "../../models";
import {runInAction} from "mobx";
import ImageContainer from "../../components/AuthContainer";
import {showMessage, useApi} from "../../services/helpers";
import {ROUTES} from "../../services/constants";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";

const logo = require("../../assets/images/logo.png")

function SignIn() {
  const rootStore = useStores()
  const {loginStore} = rootStore
  const api = useApi()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);

  const getPendingRequests = () => {
    api.getPendingRequests('', 1, '').then((result) => {
      if (result.kind === "ok") {
        const {count} = result.data
        loginStore.setPendingRequests(count);
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const login = (data) => {
    setLoading(true)
    api.login(data.email, data.password).then((result) => {
      if (result.kind === "ok") {
        runInAction(() => {
          loginStore.setUser(result.response)
          loginStore.setApiToken(result.response.token.access_token)
        })
        getPendingRequests()
        navigate(ROUTES.CALENDAR)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            showMessage('Invalid credentials')
          }
        } else {
          showMessage()
        }
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const validationSchema =
    Yup.object().shape({
      email: Yup.string().email().required(),
      password: Yup.string().required(),
    })

  const initialValues = {
    email: "",
    password: "",
  };


  return (
    <ImageContainer>
      <MDBox pt={4} pb={3} px={3} width={{xs: '100%', md: 500}}>
        <MDBox mb={2} textAlign={'center'}>
          <img
            alt="logo"
            src={logo}
          />
        </MDBox>
        <MDBox mt={3} mb={5} textAlign="center">
          <MDTypography
            color="info"
            fontWeight="medium"
            textGradient
            sx={{fontSize: 36}}
          >
            Sign In
          </MDTypography>
        </MDBox>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={values => {
            login(values);
          }}
        >
          {({errors, touched, isValid}) => (
            <Form style={{display: 'flex', flexDirection: 'column', flex: 1}}>
              <Field name="email">
                {({field}) => {
                  return (
                  <MDBox mb={2}>
                    <MDInput
                      type="email"
                      label="Email"
                      variant="standard"
                      placeholder="john@example.com"
                      fullWidth
                      error={touched.email === true && errors.email !== undefined}
                      helperText={touched.email === true && errors.email && errors.email}
                      {...field}
                    />
                  </MDBox>
                  )
                }
                }
              </Field>
              <Field name="password">
                {({field}) => {
                  return (
                    <MDBox mb={2}>
                      <MDInput
                        type="password"
                        label="Password"
                        variant="standard"
                        fullWidth
                        placeholder="************"
                        error={touched.password === true && errors.password !== undefined}
                        helperText={touched.password === true && errors.password && errors.password}
                        {...field}
                      />
                    </MDBox>
                  )
                }
                }
              </Field>
              <MDBox mt={6} mb={1} mx={8}>
                <MDButton
                  fullWidth
                  size={'large'}
                  loading={loading}
                  disabled={loading || !isValid}
                  variant="gradient"
                  color="primary"
                  type='submit'
                >
                  Sign In
                </MDButton>
              </MDBox>
            </Form>
          )}
        </Formik>
        <MDBox mt={1} mb={3} textAlign="center">
          <MDTypography
            component={Link}
            to={ROUTES.FORGOT_PASSWORD}
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
          >
            Forgot your password?
          </MDTypography>
        </MDBox>
        <MDBox mt={6} mx={8}>
          <MDButton variant="gradient" color="secondary" fullWidth href={ROUTES.ADMIN}>
            Super Admin Sign In
          </MDButton>
        </MDBox>
      </MDBox>
    </ImageContainer>
  );
}

export default SignIn;
