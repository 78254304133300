import React, {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import {APISuccessMessage, showMessage, useApi} from "../../services/helpers"
import MDBox from "../../components/MDBox";
import {Button, Grid} from "@mui/material";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import {useStores} from "../../models";

const Integrations = () => {
  const api = useApi()
  const rootStore = useStores()
  const {loginStore} = rootStore
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [connected, setConnected] = useState(false)
  const searchParams = new URLSearchParams(document.location.search)


  const getInitializeHighLevel = () => {
    setLoading(true)
    api.getInitializeHighLevel().then((result) => {
      if (result.kind === "ok") {
        window.location.replace(result.data.url);
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const getCallback = () => {
    let code = searchParams.get('code')
    if (code != null && code !== "") {
      api.getCallBackHighLevel(code).then((result) => {
        if (result.kind === "ok") {
          setMessage("Connected to CRM. Access Code: " + result.data.hl_access_token)
          setConnected(true)
        } else {
          setMessage(result.errors.error)
          showMessage();
        }
      })
        .catch(err => {
          showMessage();

        })
        .finally(() => {
          setLoading(false)
          var url = new URL(window.location.href);

// Get the query string parameters
          var params = new URLSearchParams(url.search);

// Remove the 'code' parameter
          params.delete('code');

// Set the new query string without the 'code' parameter
          url.search = params.toString();

// The new URL without the 'code' parameter
          window.location.href = url.toString()
        })
    }
  }

  const getCompanyDetailHighLevel = () => {

    api.getCompanyHighlevel().then((result) => {
      if (result.kind === "ok") {
        if (result?.data?.hl_access_token) {
          setMessage(result.data.hl_access_token)
        }
      }
    })
      .catch(err => {
        showMessage();
      })
      .finally(() => setLoading(false))
  }


  useEffect(() => {
    getCompanyDetailHighLevel()
    getCallback()
  }, [])


  return (
    <DashboardLayout showCard loginRequired>
      {!connected && loginStore.is_superuser && <Grid item style={{justifyItems: "center", alignItems: "center"}}>
        <MDBox style={{
          width: "30%",
          display: "inline-block",
          textAlign: "center"
        }}>
          <MDTypography>CRM</MDTypography>

        </MDBox>

        <MDBox style={{
          width: "40%",
          display: "inline-block",
          textAlign: "right",
          alignItems: "right",
        }}>
          <MDButton style={{
            backgroundColor: "#e35505",
            color: "white"
          }}
                    onClick={() => {
                      getInitializeHighLevel()
                    }}
          >

            Connect with CRM

          </MDButton>
        </MDBox>

      </Grid>}

      <Grid item style={{justifyItems: "center", alignItems: "center", marginTop: "1%"}}>
        <MDBox style={{
          width: "100%",
          display: "inline-block",
          textAlign: "center",
          alignItems: "center",
          marginTop: "20%"
        }}>
          <label>
            {loginStore.level === 3 ? message : "Contact us to upgrade for Simple Scheduler CRM Integration."}
          </label>
        </MDBox>


      </Grid>

    </DashboardLayout>
  )
}

export default Integrations
