import DashboardLayout from "../../components/LayoutContainers/DashboardLayout"
import React, {useEffect, useRef, useState} from "react"
import MDButton from "../../components/MDButton"
import {showMessage, useApi} from "../../services/helpers"
import MDBox from "../../components/MDBox";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {CircularProgress, FormLabel, Grid, Input,} from "@mui/material";
import MDInput from "../../components/MDInput";
import {ReactComponent as Search} from '../../assets/svg/search.svg'
import Pagination from "../../components/Pagination/Pagination";
import ConfirmDialogModal from "../../components/ConfirmDialogModal";
import ModalItem from "../../components/ModalItem";
import InputMask from "react-input-mask";
import DataTable from "../../components/DataTable";
import {ReactComponent as FiltroIcon} from '../../assets/svg/FunnelSimple.svg'
import './style.css'
import {useStores} from "../../models";


const pencilIcon = require("../../assets/icons/pencil_btn.png")
const trashIcon = require("../../assets/icons/delete_btn.png")

const dataTableModel = {
  columns: [
    {Header: "Name", accessor: "name", width: '13%'},
    {Header: "Phone Number", accessor: "phone_number", width: '10%'},
    {Header: "Email", accessor: "email", width: '10%'},
    {Header: "Actions", accessor: "actions", width: '7%', disableOrdering: true}
  ],
  rows: [],
};

const renderTableRow = (item, selectItemEdit, selectItemDelete, is_staff) => {
  item.assigned_team_bkp = item.assigned_team
  item.assigned_team = item.assigned_team?.title
  if (!is_staff) return item
  item.actions = (<>
    <img
      alt="..."
      onClick={() => selectItemEdit(item)} style={{cursor: 'pointer'}}
      src={pencilIcon}
    />
    <img
      alt="..."
      onClick={() => selectItemDelete(item)} style={{cursor: 'pointer', marginLeft: 5}}
      src={trashIcon}
    />
  </>)
  return item
}

const StaffUsers = () => {
  const api = useApi();
  const rootStore = useStores()
  const {loginStore} = rootStore
  const [selectedElement, setSelectedElement] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfItems, setNumberOfItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openEditConfirmModal, setEditOpenConfirmModal] = useState(false);
  const formikRef = useRef();
  const [recordList, setRecordList] = useState({...dataTableModel});
  const searchQueryRef = useRef("");
  const lastKeyPressedRef = useRef(null);

  const getStaffUsers = (searchData, page = 1, ordering = "") => {
    setLoading(true)
    api.getStaffUsers(searchData, page, ordering).then((result) => {
      if (result.kind === "ok") {
        const {count, results} = result.data
        setNumberOfItems(count)
        const tmp = {...dataTableModel}
        tmp.rows = results.map(e => renderTableRow(e, selectItemEdit, selectItemDelete, loginStore.is_staff))
        setRecordList(tmp)
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const addStaffUser = (data) => {
    if (!loginStore.is_staff) return
    setLoading(true)
    api.addStaffUser(data).then((result) => {
      if (result.kind === "ok") {
        getStaffUsers(searchQuery, currentPage)
        cancelItemDelete()
        showMessage('Staff User added successfully', 'success')
      } else if (result.kind === "bad-data") {
        formikRef.current.setErrors(result.errors)
        showMessage('Validation errors found')
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const editStaffUser = () => {
    if (!loginStore.is_staff) return
    setLoading(true)
    api.editStaffUser(selectedElement).then((result) => {
      if (result.kind === "ok") {
        getStaffUsers(searchQuery, currentPage)
        cancelItemDelete()
        showMessage('Staff User updated successfully', 'success')
      } else if (result.kind === "bad-data") {
        formikRef.current.setErrors(result.errors);
        showMessage('Validation errors found');
        setEditOpenConfirmModal(false);
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const deleteStaffUser = () => {
    if (!loginStore.is_staff) return
    setLoading(true)
    api.deleteStaffUser(selectedElement.id).then((result) => {
      if (result.kind === "ok") {
        getStaffUsers(searchQuery, currentPage)
        cancelItemDelete()
        showMessage('Staff User deleted successfully', 'success')
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const onColumnOrdering = (ordering) => {
    const {column, order} = ordering
    if (column === '') {
      getStaffUsers(searchQuery)
    } else if (order === 'asce') {
      getStaffUsers(searchQuery, 1, `${column}`)
    } else {
      getStaffUsers(searchQuery, 1, `-${column}`)
    }
  }

  const validationSchemaCreate =
    Yup.object().shape({
      first_name: Yup.string().required('First Name is required'),
      last_name: Yup.string().required('Last Name is required'),
      phone_number: Yup.string().required('Cell Phone is required'),
      email: Yup.string().required('Email is required'),
      password: Yup.string().required('Password is required'),
      new_password2: Yup.string().required('Confirm password is required').oneOf([Yup.ref('password'), null], "Passwords doesn't match"),
    })

  const validationSchemaEdit =
    Yup.object().shape({
      first_name: Yup.string().required('First Name is required'),
      last_name: Yup.string().required('Last Name is required'),
      phone_number: Yup.string().required('Cell Phone is required'),
      email: Yup.string().required('Email is required'),
    })

  const initialValues = {
    first_name: selectedElement ? selectedElement.first_name : "",
    last_name: selectedElement ? selectedElement.last_name : "",
    phone_number: selectedElement ? selectedElement.phone_number : "",
    email: selectedElement ? selectedElement.email : "",
    password: "",
    new_password2: "",
  };

  const addeditStaffUser = (data) => {
    if (selectedElement) {
      setSelectedElement({...data, id: selectedElement.id})
      setEditOpenConfirmModal(true)
    } else {
      addStaffUser(data);
    }
  }

  const selectItemDelete = (item) => {
    setOpenConfirmModal(true)
    setSelectedElement(item)
  }

  const cancelItemDelete = () => {
    setEditOpenConfirmModal(false)
    setOpenConfirmModal(false)
    setOpen(false)
    setSelectedElement(null)
  }

  const selectItemEdit = (item) => {
    setSelectedElement(item)
    setOpen(true)
  }

  const prepareCall = () => {
    lastKeyPressedRef.current = (new Date()).getTime()
    setTimeout(() => {
      const now = (new Date()).getTime()
      if (loading || now - lastKeyPressedRef.current < 1000) {
        return
      }
      getStaffUsers(searchQueryRef.current.value)
    }, 1000)
  }

  useEffect(() => {
    getStaffUsers(searchQuery)
  }, [])

  return (
    <DashboardLayout showCard loginRequired>
      <Grid container>
        <Grid item xs={8}>
          <MDBox sx={{backgroundColor: '#EBEBEB', borderRadius: 20, position: 'relative'}} px={5}>
            <Search style={{position: 'absolute', bottom: 7, left: 10}}/>
            <Input
              inputRef={searchQueryRef}
              fullWidth
              placeholder="Search Staff User"
              type="text"
              onInputCapture={prepareCall}
            />
          </MDBox>
        </Grid>
        <Grid item xs={1} display="flex" alignItems="center" justifyContent="center">
          {loading ? <CircularProgress size={24} color="primary"/> : <FiltroIcon/>}
        </Grid>
        <Grid item xs={3} justifyContent={'flex-end'} display={'flex'}>
          {loginStore.is_staff && <MDButton ml={'auto'} disabled={loading} variant="gradient" color="primary" onClick={() => setOpen(true)}>
            Add Staff User
          </MDButton>}
        </Grid>
      </Grid>

      {recordList.rows.length > 0
        ? <DataTable table={recordList} onColumnOrdering={onColumnOrdering}/>
        : <p style={{display: 'flex', height: '60vh', justifyContent: 'center', alignItems: 'center', fontSize: 20}}>No staff users found</p>
      }

      <Pagination
        currentPage={currentPage}
        totalCount={numberOfItems}
        pageSize={8}
        onPageChange={page => {
          getStaffUsers('', page)
          setCurrentPage(page)
        }}
      />
      <ConfirmDialogModal
        title={'Do you want to delete this staff user?'}
        description={`${selectedElement?.id} - ${selectedElement?.name}`}
        cancelText={'Cancel'}
        confirmText={'Confirm'}
        open={openConfirmModal}
        handleClose={() => cancelItemDelete()}
        handleConfirm={() => deleteStaffUser()}
      />
      <ConfirmDialogModal
        title={'Do you want to update this staff user?'}
        description={`${selectedElement?.id} - ${selectedElement?.first_name} ${selectedElement?.last_name}`}
        cancelText={'Cancel'}
        confirmText={'Confirm'}
        open={openEditConfirmModal}
        handleClose={() => setEditOpenConfirmModal(false)}
        handleConfirm={() => editStaffUser()}
      />
      <ModalItem
        open={open}
        title={selectedElement ? 'Edit Staff User' : 'Add Staff User'}
        handleClose={() => cancelItemDelete()}
        scrollable={false}
      >
        <Formik
          innerRef={formikRef}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={selectedElement ? validationSchemaEdit : validationSchemaCreate}
          onSubmit={values => {
            addeditStaffUser(values)
          }}
        >
          {({errors, touched, isValid, setFieldValue, values}) => (
            <Form style={{display: 'flex', flexDirection: 'column', flex: 1}}>
              <Field name="first_name">
                {({field}) => {
                  return (
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        label="First Name *"
                        variant="standard"
                        fullWidth
                        error={touched.first_name === true && errors.first_name !== undefined}
                        helperText={touched.first_name === true && errors.first_name && errors.first_name}
                        {...field}
                      />
                    </MDBox>
                  )
                }
                }
              </Field>
              <Field name="last_name">
                {({field}) => {
                  return (
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        label="Last Name *"
                        variant="standard"
                        fullWidth
                        error={touched.last_name === true && errors.last_name !== undefined}
                        helperText={touched.last_name === true && errors.last_name && errors.last_name}
                        {...field}
                      />
                    </MDBox>
                  )
                }
                }
              </Field>
              <Field name="email">
                {({field}) => {
                  return (
                    <MDBox mb={2}>
                      <MDInput
                        type="email"
                        label="Email *"
                        variant="standard"
                        fullWidth
                        error={errors.email !== undefined}
                        helperText={errors.email && errors.email}
                        {...field}
                      />
                    </MDBox>
                  )
                }
                }
              </Field>
              <Field name="phone_number">
                {({field}) => {
                  touched.phone_number = true
                  return (
                    <MDBox mb={2}>
                      <InputMask
                        mask="+1(999)-999-9999"
                        disabled={false}
                        {...field}
                      ><MDInput
                        type="text"
                        label="Cell Phone *"
                        variant="standard"
                        fullWidth
                        error={touched.phone_number === true && errors.phone_number !== undefined}
                        helperText={(touched.phone_number === true && errors.phone_number) && errors.phone_number}

                      />
                      </InputMask>
                    </MDBox>
                  )
                }
                }
              </Field>
              <Field name="password">
                {({field}) => {
                  return (
                    <MDBox mb={2}>
                      <MDInput
                        type="password"
                        label="New Password"
                        variant="standard"
                        fullWidth
                        placeholder="************"
                        error={touched.password === true && errors.password !== undefined}
                        helperText={touched.password === true && errors.password && errors.password}
                        {...field}
                      />
                    </MDBox>
                  )
                }
                }
              </Field>
              <Field name="new_password2">
                {({field}) => {
                  return (
                    <MDBox mb={2}>
                      <MDInput
                        type="password"
                        label="Confirm New Password"
                        variant="standard"
                        fullWidth
                        placeholder="************"
                        error={touched.new_password2 === true && errors.new_password2 !== undefined}
                        helperText={touched.new_password2 === true && errors.new_password2 && errors.new_password2}
                        {...field}
                      />
                    </MDBox>
                  )
                }
                }
              </Field>
              <MDBox mx={'auto'} justifyContent={'center'} mt={'auto'}>
                <MDButton sx={{minWidth: 150}} size={'large'} loading={loading} disabled={loading || !isValid} variant="gradient" color="secondary" type='submit'>
                  Save Staff User
                </MDButton>
              </MDBox>
            </Form>
          )}
        </Formik>
      </ModalItem>
    </DashboardLayout>
  )
}

export default StaffUsers
