import DashboardLayout from "../../components/LayoutContainers/DashboardLayout"
import MDButton from "../../components/MDButton"
import {useNavigate} from "react-router-dom"
import MDBox from "../../components/MDBox";
import {ROUTES} from "../../services/constants";
import styles from "./style.module.css";
import MDTypography from "../../components/MDTypography";
import {useStores} from "../../models";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MuiSwitch from "@mui/material/Switch";
import React, {useEffect, useState} from "react";
import {showMessage, useApi} from "../../services/helpers";

const ProfileCalendarConfig = () => {
  const rootStore = useStores()
  const api = useApi()
  const {loginStore} = rootStore
  const navigate = useNavigate()
  const [weekMode, setWeekMode] = useState(false)
  const [monthMode, setMonthMode] = useState(false)
  const [yearMode, setYearMode] = useState(false)
  const [loading, setLoading] = useState(false)

  const getCompanyCalendarConfig = () => {
    setLoading(true)
    api.getCompany().then((result) => {
      if (result.kind === "ok") {
        const {week_view, month_view, year_view} = result.data
        setWeekMode(week_view)
        setMonthMode(month_view)
        setYearMode(year_view)
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const setCompanyConfig = () => {
    const data = {
      week_view: weekMode,
      month_view: monthMode,
      year_view: yearMode
    }
    setLoading(true)
    api.setCompanyConfig(data).then((result) => {
      if (result.kind === "ok") {
        getCompanyCalendarConfig()
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (loginStore.is_staff === false && loginStore.is_superuser === false) {
      navigate(-1)
    }
    getCompanyCalendarConfig()
  }, [])




  return (
    <DashboardLayout showCard loginRequired>
      <MDBox display={'flex'} flexDirection={'column'} alignItems={'center'} flex={1}>

        <MDTypography className={{fontSize: 25, color: 'black!important'}}>Company calendar configuration</MDTypography>

        <MDBox>
          <span style={{fontSize: '0.8em'}}>Week mode</span>
          <MuiSwitch checked={weekMode}  onChange={() => setWeekMode(!weekMode)} disabled={loading} color="primary"/>
        </MDBox>
        <MDBox>
          <span style={{fontSize: '0.8em'}}>Month mode</span>
          <MuiSwitch checked={monthMode}  onChange={() => setMonthMode(!monthMode)} disabled={loading} color="primary"/>
        </MDBox>
        <MDBox>
          <span style={{fontSize: '0.8em'}}>Year mode</span>
          <MuiSwitch checked={yearMode}  onChange={() => setYearMode(!yearMode)} disabled={loading} color="primary"/>
        </MDBox>
        <MDButton sx={{minWidth: 170, marginTop: 'auto'}} loading={loading}  variant="gradient" color="secondary" onClick={() => setCompanyConfig()}>
          Save
        </MDButton>
         <MDButton sx={{minWidth: 170, marginTop: 5, marginBottom: 10}}  variant="gradient" color="primary" onClick={() => navigate(-1)}>
          Cancel
        </MDButton>
      </MDBox>
    </DashboardLayout>
  )
}

export default ProfileCalendarConfig
